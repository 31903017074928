import * as React from 'react'
import { graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import './styles.scss'

const Image = loadable(() => import('/src/components/Media/Image'))

const ImageCallout = ({
    backgroundColour,
    image,
    addCta,
    callToAction,
    utm
}) => {
    if (addCta && callToAction) {
        const ctaHref = `${callToAction.url}${utm}`

        return (
            <section
                className="c-image-callout"
                style={{ backgroundColor: backgroundColour }}
            >
                <div className="container-lg">
                    <div className="c-image-callout__inner">
                        <Link to={ctaHref}>
                            <Image
                                data={image}
                                className={'c-image-callout__image'}
                            />
                        </Link>
                    </div>
                </div>
            </section>
        )
    } else {
        return (
            <section
                className="c-image-callout"
                style={{ backgroundColor: backgroundColour }}
            >
                <div className="container-lg">
                    <div className="c-image-callout__inner">
                        <Image
                            data={image}
                            className={'c-image-callout__image'}
                        />
                    </div>
                </div>
            </section>
        )
    }
}

ImageCallout.propTypes = {
    backgroundColour: PropTypes.string.isRequired,
    image: PropTypes.object,
    addCta: PropTypes.bool,
    callToAction: PropTypes.shape({
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        target: PropTypes.string
    }),
    /**
     * Optional page UTMs - e.g. `?utm_campaign=campaign_name&utm_source=source_name`
     */
    utm: PropTypes.string
}

ImageCallout.defaultProps = {
    backgroundColour: '#ffcc00',
    addCta: false
}

export default ImageCallout

export const query = graphql`
    fragment ImageCalloutForPageBuilder on WpPage_Acfpagebuilder_Layouts_ImageCallout {
        fieldGroupName
        backgroundColour
        image {
            id
            localFile {
                childImageSharp {
                    gatsbyImageData(width: 500)
                }
            }
            altText
            title
        }
        addCta
        callToAction {
            target
            title
            url
        }
    }
`
